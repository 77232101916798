import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import blogStyles from "./blog.module.scss"
import Head from "../components/head"
import HeaderBebidas from "../components/headerbebidas"

const BebidasPage = () => {
    const data = useStaticQuery(graphql`
    query{
      allContentfulProduct(filter: {categoria: {eq: "bebidas"}}) {
        edges{
          node{
            precounitario
            title
            titleEnglish
            slug
            media {
              fixed(height: 180, width: 180) {
                height
                base64
                src
                srcSet
                width
              }
          }
        }
      }
    } 
  }
    `)
  
    return (
      <Layout>
        <Head title="Beverages" />
        <h1>Beverages</h1>
        <HeaderBebidas></HeaderBebidas>
        <ol className={blogStyles.posts}>
          {data.allContentfulProduct.edges.map(edge => {
            return (
                <li className={blogStyles.post}>
                <Link to={`/blog/${edge.node.slug}`}>
                  <h2>{edge.node.titleEnglish}</h2>
                  <div class={blogStyles.productimage}>
                      <Img fixed={edge.node.media.fixed}/>
                    </div>
                    <p>USD {edge.node.precounitario}/unit</p>
                </Link>
              </li>
            )
          })}
        </ol>
      </Layout>
    )
  }

export default BebidasPage